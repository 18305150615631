import { TDTemplateType } from 'types/templates.type';
import { TemplateThumbnail } from 'components/template-management/types/template-management.type';
import { Attributes } from './attribute.type';
import BrandGuide from './brandGuide.type';
import Composition from './composition.type';
import Format, { FormatSortOrder } from './format.type';
import FormatProperties from './formatProperties.type';
import FrameType from './frameTypes.type';
import Layer from './layer.type';
import LayerProperties, { CustomAndPredefinedAnimations, TextStyling } from './layerProperties.type';
import Page from './page.type';
import Folder from './folder.type.js';
import Animation, { ActiveAnimationType, AnimationOptions, PredefinedAnimationOptions } from './animation.type.js';
import { MissingFont, TemplateFont } from './font.type';
import { MigrationScripts } from '../migrations/types/migrateFunction.type';
import { DynamicLayerInput } from './dynamicLayer.type';
import { ExpandSections } from '../components/ui-components/expand-section';

interface Template {
    designerSettings: DesignerSettings;
    templateData: TemplateData;
    templateSetup: TemplateSetup;
    templateDefaultData?: TemplateDefaultData;
    dataVariables: DataVariables;
    brandGuide?: BrandGuide;
    formats: Format[];
    frameTypes: FrameType[];
    compositions?: Composition[];
    pages?: Page[];
    layers: Layers;
    layerProperties: FrameTypeProperties;
    dynamicLayers: DynamicLayers;
    url: string;
    view: View;
    state: State;
    id: string;
}

export interface DesignerSettings {
    templateFonts: TemplateFont[];
    disableBase: boolean;
    customFormats: Format[];
    autoSave: boolean;
    enableAnimations: boolean;
    positionByLanguage: boolean;
    enableLottie: boolean;
    enableVideo: boolean;
    enableAudio: boolean;
    gridSize: number;
    exportForPlatform: ExportForPlatform;
    studioProfileId: number | undefined;
    customCSS?: boolean;
    customJS?: boolean;
    useHigherFps?: boolean;
    bannerLoop?: BannerLoopOptions;
    defaultUnit?: UnitOptions;
    highlightedCharacter: '[' | '(' | '{' | '*';
    backgroundbackupImageWaitTime?: number;
}

export interface TemplateSetup {
    templateVersion: string;
    finishedMigrations?: MigrationScripts[];
    adobe?: {
        identifier: string;
        downloadUrl?: string;
        treeStructure?: (Composition | Folder)[];
        previewUrls?: {
            [formatKey: Format['key']]: string;
        };
        file?: {
            name: string | null;
            size: number | null;
        };
        unit?: string;
        ppi?: number;
    };
    feedMappingStructure?: {
        [key: FrameType['key']]: FeedMappingStructure;
    };
}

export interface TemplateData {
    title: string;
    description: string | null;
    groupKey?: string;
    brands: string[];
    identifier: string;
    type: TDTemplateType;
    version: number;
    releasedBy?: number;
    releasedAt?: Date;
    releasedVersion?: number;
    image: string;
    createdDate: Date | string;
    updatedDate: Date | string;
    status: Status;
    settings: TemplateDataSettings;
    public: boolean;
    customData?: object;
    thumbnail?: TemplateThumbnail | TemplateThumbnail[];
}

export interface TemplateDataSettings {
    dynamicFrames: boolean;
    formats: Format[];
    formatSets?: { title: string; formats: Format[] }[];
    gridSize?: number;
    lottieTemplate?: boolean;
    positionByLanguage?: boolean;
    ExportForPlatform?: ExportForPlatform;
    backgroundbackupImageWaitTime?: number;
}

export interface DynamicLayers {
    base: DynamicLayerInput[];
    [key: FrameType['key']]: DynamicLayerInput[];
}

export interface Layers {
    [key: FrameType['key']]: Layer[];
}

export interface DataVariables {
    base: {
        [key: Layer['key']]: {
            [key in Attributes]: Attributes;
        };
    };
    [key: FrameType['key']]: {
        [key: Layer['key']]: {
            [key in Attributes]: Attributes;
        };
    };
}

export interface TemplateDefaultData {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: FrameType['key']]: any | { [key: string]: any };
}

export interface View {
    frameType: string;
    currentView: CurrentView;
    showRulers: boolean;
    showLayoutGrid: boolean;
    showSafeZones: boolean;
    showTimeline: boolean;
    testEnvironment: {
        open: boolean;
        uuid: string;
        dataModel: string;
    };
    hideLeftSidebar: boolean;
    showLayerPanel: boolean;
    showFormatManagementDialog: boolean;
    showComments: boolean;
    showVersionHistory: boolean;
    showTab: RightSidebarTab;
    showHelpPanel: boolean;
    showSidekick: boolean;
}

export enum RightSidebarTab {
    LayerEdit = 'layerEdit',
    Hover = 'hover',
    Animate = 'animate'
}

export enum CurrentView {
    Design = 'Design',
    DynamicLayers = 'DynamicLayers',
    FeedMapping = 'FeedMapping',
    Adobe = 'Adobe'
}

/**
 * To keep track of media that is large in file size.
 */
export interface LargeMedia {
    frameType: View['frameType'];
    format: Format['key'] | 'general';
    layer: Layer['key'] | 'format';
    expandSection: ExpandSections;
    imageSizeByte: number;
    previewRoute: string;
}

/**
 * To keep track of image sizes that are large then a certain megapixel size.
 */
export interface LargeImageSize {
    frameType: View['frameType'];
    format: Format['key'] | 'general';
    layer: Layer['key'] | 'format';
    expandSection: ExpandSections;
    megapixel: number;
    size: string;
    previewRoute: string;
}

export interface State {
    isTourActive?: boolean;
    isPlaying: boolean;
    openItemTree: boolean;
    displayFormats: string[];
    selectedLayers: Layer[];
    selectedFormats: string[];
    selectedFormatSets: Format['sets'];
    formatOrder: FormatSortOrder;
    activeAnimations: {
        type: ActiveAnimationType;
        keyframeKey?: Animation['id'];
        animationKey?: keyof CustomAndPredefinedAnimations | null;
        layer: Layer['key'];
        emptyAnimation?: boolean;
    }[];
    restoreVersion: number | null;
    isUploading: boolean;
    missingFonts?: MissingFont[];
    conflictingAnimations?: {
        [key: FrameType['key']]: {
            [key: Layer['key']]: (keyof LayerProperties['animations'])[];
        }[];
    } | null;
    largeMedia?: LargeMedia[];
    largeImageSize?: LargeImageSize[];
    keyframeOverlaps?: {
        [key in AnimationOptions | PredefinedAnimationOptions]: AnimationOptions | PredefinedAnimationOptions[];
    };
    timelineHeight: number;
    leftSidebarWidth: number;
    dynamicLayersLeftSidebarWidth: number;
    formatsWithAudio: Format['key'][];
    changedAfterSave: boolean;
    lastUsedFont?: {
        fontSource?: TextStyling['fontSource'];
        fontFamily?: TextStyling['fontFamily'];
        fontVariant?: TextStyling['fontVariant'];
        fontName?: TextStyling['fontName'];
    };
}

export interface FrameTypeProperties {
    general: {
        base: {
            properties: FormatProperties;
            [layerKey: Layer['key']]: FormatProperties | LayerProperties; // Only LayerProperties, but without FormatProperties, there is a TypeScript error.
        };
        [frameTypeKey: FrameType['key']]: {
            properties: FormatProperties;
            [layerKey: Layer['key']]: FormatProperties | LayerProperties; // Only LayerProperties, but without FormatProperties, there is a TypeScript error.
        };
    };
    [formatKey: Format['key']]: {
        base: {
            properties: FormatProperties;
            [key: Layer['key']]: FormatProperties | LayerProperties; // Only LayerProperties, but without FormatProperties, there is a TypeScript error.
        };
        [frameTypeKey: FrameType['key']]: {
            properties: FormatProperties;
            [layerKey: Layer['key']]: FormatProperties | LayerProperties; // Only LayerProperties, but without FormatProperties, there is a TypeScript error.
        };
    };
}

export interface FeedMappingStructure {
    datasetId?: string;
    feedId?: string;
    itemId?: string;
}

export type Status = 'available' | 'draft';

export enum BannerLoopOptions {
    NoLoop = 'noLoop',
    SingleLoop = 'singleloop',
    DoubleLoop = 'doubleloop',
    TrippleLoop = 'tripleloop',
    InfiniteLoop = 'infiniteloop'
}

export enum HighlightedCharacterOptions {
    SquareBracket = '[',
    Parentheses = '(',
    CurlyBraces = '{',
    Star = '*'
}

export enum UnitOptions {
    Pixels = 'px',
    Percentages = '%'
}

export enum ExportForPlatform {
    General = 'general',
    Studio = 'studio',
    Xandr = 'xandr',
    Adform = 'adform',
    Factor11 = 'factor11',
    AxelSpringer = 'axelSpringer'
}

export default Template;
